//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { iSearch, iScroll } from "@/icomponents";
export default {
  components: {
    iSearch,
    iScroll,
  },
  props: {
    departmentList: {
      type: Array,
      default: () => [],
    },
    hcpForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataList: [],
      isShowPopup: false,
      keyword: "",
      topHeight: 0,
      bottomHeight: 0,
      allLoaded: true,
      pageIndex: 1,
      pageSize: 10,
      selectedDepartmentList: [], // 已选择科室list
    };
  },
  watch: {
    keyword() {
      this.$iDelay(() => {
        this.search();
      }, 200);
    },
    departmentList() {
      this.dataList = this.departmentList;
    },
  },
  created() {
    this.dataList = this.departmentList;
  },
  methods: {
    showPopup() {
      this.loadData();
      this.isShowPopup = true;
      this.init();
    },
    init() {
      this.$nextTick(() => {
        this.topHeight = 2.3 * this.$refs.headerBox.$el.offsetHeight;
      });
    },
    search() {
      this.loadData();
    },
    loadData() {
      this.$refs.scrollWrapper.scrollListener();
      this.dataList = this.departmentList.filter((e) =>
        e.name.includes(this.keyword)
      );
      this.dataList.map((ele) => {
        if (this.hcpForm.department == ele.name) {
          this.$set(ele, "isSelected", true);
        } else {
          this.$set(ele, "isSelected", false);
        }
      });
    },
    // 选择科室
    selectDepartment(item) {
      this.dataList.map((item) => {
        item.isSelected = false;
      });
      item.isSelected = !item.isSelected;
    },
    // 确定选择
    sureBtn() {
      const selectedData = this.dataList.find((e) => e.isSelected);
      if (!selectedData) {
        this.$iToast("请选择科室");
        return false;
      }
      this.departmentGoBack();
      this.$parent.initForm({
        department: selectedData.name,
      });
    },
    // 返回
    departmentGoBack() {
      this.isShowPopup = false;
    },
  },
};
