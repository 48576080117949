//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import newHead from '@/components/newHead/newHead'
    import {miceService} from "../../service/miceService";
    import { iField } from '@/icomponents';
    import Hospital from './Hospital.vue'; //选择医院
	import Department from './Department.vue'; //选择科室
    export default {
        components: {
            newHead,
            iField,
            Hospital,
            Department
        },
        props: {
            isCommon: {
                type: Boolean,
                default: () => true
            },
            isImport: { // 是否是导入界面
                type: Boolean,
                default: true
            },
        },
        data () {
            return {
                isShowPopup: false,
                isAdd: true, // 是否是新增
                isDelete: false, // 是否可删除
                personInfo: {
                    IsSyncContact: ["是否同步常用联系人"],
                    hospital: '',
                    department: ''
                },
                title: '选择参会人',
                // 益普生新加
                tenantCode: '',
                departmentList: []
            }
        },
        created() {
            this.tenantCode = this.iStorage.get('tenant');

            this.getConfig();
        },
        computed: {
            isHcp() {
                return this.isCommon || (this.isImport && this.personInfo.hcpId != '00000000-0000-0000-0000-000000000000')
            }
        },
        methods: {
            addPersonCancel () {
                this.goBack()
            },
            async addPerson () {
                let verifyMap = ["name",'hospital', "department", "phoneNumber"]; //必填项key集合
                let verify = verifyMap.some((ele) => {
                    return !this.personInfo[ele];
                })
                if(verify) {
                    this.$iToast('参会人信息不完整');
                    return false;
                }
                let mobile = /^(13[0-9]{9})|(18[0-9]{9})|(14[0-9]{9})|(17[0-9]{9})|(15[0-9]{9})$/;
               if (!mobile.test(this.personInfo.phoneNumber)) {
                   this.$iToast('手机号格式不正确');
                   return false;
               }
                let params = {
                    name: this.personInfo.name,
                    Email: this.personInfo.email,
                    Hospital: this.personInfo.hospital,
                    Department: this.personInfo.department,
                    PhoneNumber: this.personInfo.phoneNumber
                }
                if (!this.isAdd) {
                    params.ContactId = this.personInfo.contactId
                    params.HcpId = this.personInfo.hcpId
                }
                let funName = this.isAdd || this.isCommon ? 'ContactAdd' : 'ContactUpdate'
                if (this.isDelete) {
                    params.invitationId = this.personInfo.invitationId
                    funName = 'InvitationUpdate'
                }
                if (['ContactAdd','InvitationUpdate'].includes(funName)) {
                    params.miceType = 0
                    params.attenderType = this.personInfo.hcpId ? 1 : 3 //参会人性质：1:hcp 2:employee 3:手工录入
                    params.ExtId = this.personInfo.extId || '00000000-0000-0000-0000-000000000000'
                }
                if (!this.isImport) {
                    params.IsSyncContact = this.personInfo.IsSyncContact.length > 0
                }
                let res = await miceService[funName](params)
                if (res && res.success) {
                    this.$iToast(this.isAdd ? '新增参会人信息成功':'编辑参会人信息成功');
                    this.$parent.isCommon = true
                    this.goBack()
                }
            },
            showPopup (info, isDelete = false) {
                this.isAdd = true
                this.isDelete = isDelete
                if (info) {
                    this.personInfo = JSON.parse(JSON.stringify(info))
                    this.personInfo.IsSyncContact=["是否同步常用联系人"]
                    this.isAdd = false
                }
                this.isShowPopup = true;
            },
            goBack () {
                this.personInfo = {
                    IsSyncContact: ['是否同步常用联系人']
                }
                this.isShowPopup = false
                this.$parent.loadData()
            },
            async addPersondelete () {
                let params = {}
                let funName = ''
                if (this.isImport) {
                    funName = 'ContactDelete'
                    params.ContactId = this.personInfo.contactId
                } else {
                    funName = 'InvitationDelete'
                    params. InvitationId = this.personInfo.invitationId
                }
                console.log(this.personInfo)
                let res = await miceService[funName](params)
                if (res && res.success) {
                    this.$iToast('删除成功');
                    this.goBack()
                }
            },
            //打开医院
			openHospital() {
				this.$refs.hospital.showPopup()
			},
			// 打开科室选择
			openDepartment() {
                this.$refs.department.showPopup();
            },
            // 获取医院科室数据
            getConfig() {
                let tpmParams = {
                    collection: "cfg-ievent-eventEditor",
                    useCaching: true,
                    cachingKey: "",
                    filter: {
                        tenantCode: this.tenantCode
                    }
                };

                miceService.querysettings(tpmParams).then(res => {
                    if(res && res.content) {
                        let departmentList = res.content[0].departmentList || [];
                        this.departmentList = departmentList.map(e => {
                            return { name: e }
                        });
                    }
                });
            },
            // 更新医院、科室
			initForm(response) {
                Object.assign(this.personInfo, response);
                console.log('更新', this.personInfo);
			},
        }
    }
