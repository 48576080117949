//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import { iSearch, iScroll } from '@/icomponents';
    import {miceService} from "../../service/miceService";
    import { getCookie } from 'tiny-cookie';
	export default {
		components: {
			iSearch,
			iScroll
		},
		props: {
			hcpForm: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				isShowPopup: false,
				keyword: '',
				topHeight: 0,
				bottomHeight: 0,
				allLoaded: false,
				pageIndex: 1,
				pageSize: 20,
				dataList: []
			}
		},
		watch: {
			keyword() {
				this.$iDelay(() => {
					this.search();
				}, 200);
			}
		},
		created() {

		},
		methods: {
			// 打开popup
			showPopup() {
				this.isShowPopup = true;
				this.pageIndex = 1;
				this.dataList = [];
				this.keyword = "";
                this.loadData();
                this.init();
            },
            init() {
                this.$nextTick(() => {
                    this.topHeight = 2.3 * this.$refs.headerBox.$el.offsetHeight;
                })
            },
			// 关键字查询
			search() {
				this.loadData()
			},
			// 获取医院数据
			loadData(obj = {
				pageIndex: 1
			}) {
				this.allLoaded = false;
                this.pageIndex = obj.pageIndex || this.pageIndex;
                
                let eventData = this.iStorage.get('eventData') || JSON.parse(getCookie("eventData"))
				let params = {
					searchInput: this.keyword,
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
					city: eventData.cityDictVal || '',
					IsOnlyShowOwer: true
				}
				miceService.QueryHospital(params).then(res => {
					this.$refs.scrollWrapper.scrollListener();
					if(this.pageIndex == 1) {
						this.dataList = [];
					}
					this.dataList = this.dataList.concat(res.content.rows || []);
					this.dataList.map(ele => {
						if(this.hcpForm.hospital == ele.name) {
							this.$set(ele, "isSelected", true)
						} else {
							this.$set(ele, "isSelected", false)
						}
					})
					if(this.dataList.length == res.content.total) {
						this.allLoaded = true;
					}
				})
			},
			// 选择医院
			selectHospital(hospital) {
				this.dataList.map(item => {
					item.isSelected = false;
				});
				hospital.isSelected = !hospital.isSelected;
			},
			// 确定选择
			sureBtn() {
				const selectedData = this.dataList.find(e => e.isSelected);
				if(!selectedData) {
					this.$iToast("请选择医院");
					return false;
				}
				this.hospitalGoBack();
				this.$parent.initForm({
					hospital: selectedData.name,
					rowId: selectedData.rowId
				});
			},
			// 返回
			hospitalGoBack() {
				this.isShowPopup = false;
			},
		}
	}
