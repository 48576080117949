//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import newHead from '@/components/newHead/newHead'
    import { iScroll } from '@/icomponents';
    import addrqButton from "@/components/eventList/addrqButton";
    import { miceService } from '@/service/miceService';
    import personInfoCard from "./personInfoCard";
    import addPersonInfo from './addPersonInfo.vue'
    export default {
        components: {
            personInfoCard,
            newHead,
            iScroll,
            addrqButton,
            addPersonInfo
        },
        data () {
            return {
                personList: [],
                pageIndex: 1,
                pageSize: 10,
                keywordName: '',
                keywordHospital: '',
                allLoaded: false,
                topHeight: 0,
                bottomHeight: 0,
                isCommon: true,
                selectList: []
            }
        },
        mounted() {
            this.getScrollBoxHeight();
        },
        watch: {
            isCommon () {
                this.pageIndex = 1
                this.personList = [];
                this.keywordName = ''
                this.keywordHospital = ''
                this.loadData()
            },
            keywordName () {
                this.pageIndex = 1
                this.loadData()
            },
            keywordHospital () {
                this.pageIndex = 1
                this.loadData()
            }
        },
        created() {
            this.loadData()
        },
        computed:{
            selectedLength () {
                return this.selectList.length
            }
        },
        methods: {
            goBack () {
                this.$router.back()
            },
            async loadData (val) {
                if (val) {
                    this.pageIndex = val.pageIndex
                }
                let params = {
                    pageNo: this.pageIndex,
                    pageSize: this.pageSize,
                    Name: this.keywordName,
                    Hospital: this.keywordHospital,
                    IsFavoritecontact: this.isCommon,
                    meetingId: this.$route.query.mettingId,
                    // 会议性质：视频会议0/线下会议1/不限-1
                    miceType: 0,
                }
                this.allLoaded = true
                let res = await miceService.QueryContants(params)
                if (res && res.success) {
                    if (this.pageIndex == 1) {
                        this.personList = [];
                    }
                    this.allLoaded = false
                    res.content.rows.forEach((item) => {
                        item.selected = false
                        this.selectList.forEach(ele => {
                            if (ele.contactId == item.contactId && ele.hcpId == item.hcpId) {
                                item.selected = true
                            }
                        })
                    })
                    this.$refs.scrollWrapper.scrollListener();
                    this.personList = [...res.content.rows, ...this.personList]
                    if (this.personList.length == res.content.total) {
                        this.allLoaded = true;
                    }
                }
            },
            getScrollBoxHeight() {
                this.$nextTick(() => {
                    const event_header_container = document.getElementsByClassName("event_header_container");
                    const footerBtn = document.getElementsByClassName("footerBtn");
                    this.topHeight = event_header_container ? event_header_container[0].clientHeight : 0;
                    this.bottomHeight = footerBtn ? footerBtn[0].clientHeight : 0;
                })
            },
            addPerson () {
                this.$refs.addPersonInfo.showPopup()
            },
            selectInfo (item) {
                item.selected = !item.selected
                if (item.selected) {
                    this.selectList.push(item)
                } else {
                    let itemIndex = 0
                    this.selectList.forEach((ele, index) => {
                        if (ele.contactId != item.contactId && ele.hcpId != item.hcpId) {
                            itemIndex = index
                        }
                    })
                    this.selectList.splice(itemIndex, 1)
                }
            },
            editInfo (info) {
                this.$refs.addPersonInfo.showPopup(info)
            },
            async importContact () {
                let params = {
                    MettingId: this.$route.query.mettingId,
                    Contacts: []
                }
                this.selectList.forEach(item => {
                    params.Contacts.push({
                        Name: item.name,
                        Hospital: item.hospital,
                        Department: item.department,
                        Email: item.email,
                        PhoneNumber: item.phoneNumber,
                        ContactId: item.contactId,
                        ExtId: item.extId,
                        attenderType: item.attenderType,//参会人性质：1:hcp 2:employee 3:手工录入
                    })
                })
                if (params.Contacts.length == 0) {
                    this.$iToast('请选择参会人');
                    return false
                }
                let res = await miceService.ContactImport(params)
                if (res && res.success) {
                    this.$iToast('导入参会人信息成功');
                    this.goBack()
                }
            }
        }
    }
